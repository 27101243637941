<template>
	<slot/>

	<lazy-app-app-mobile-nav v-if="isMobileNavVisible"/>

	<client-only>
		<lazy-share-sheet v-if="indexStore.shareSheetData" v-model="indexStore.isShareSheetVisible" v-bind="indexStore.shareSheetData"/>
	</client-only>
</template>

<script setup lang="ts">
import {useIndexStore} from "../store";
import {useGlobalStore} from "@spoferan/nuxt-spoferan/store";
import {NativeApp} from "@spoferan/nuxt-spoferan/services";
const indexStore = useIndexStore();
const globalStore = useGlobalStore();
const route = useRoute();
const {$device, $auth, $echo} = useNuxtApp();

const isMobileNavVisible = computed(() => {
	if ($device.isDesktop) {
		return false;
	}

	const pageNamesWithCustomAppBarSuffixes = ['events-event-participate', 'my-profile-edit', 'clubs-create', 'clubs-club-manage'];
	for (const pageSuffixKey in pageNamesWithCustomAppBarSuffixes) {
		if (route.name?.startsWith(pageNamesWithCustomAppBarSuffixes[pageSuffixKey])) {
			return false;
		}
	}

	return true;
});

watch(() => $auth.id, (userId, oldUserId) => {
	handleAuthUserChange(userId, oldUserId);
});

function handleAuthUserChange(userId?: number|null, oldUserId?: number|null) {
	indexStore.loadFollows();
	globalStore.loadUnreadNotificationsCount();
	indexStore.initPushNotificationsConfig();

	if ($echo && oldUserId) {
		$echo.unsubscribe(`user.${oldUserId}`);
	}

	// Listen to the websocket when a new notification for the currently logged user is added to the database, so we can immediately show it on the list
	if ($echo && userId) {
		$echo.subscribe(`user.${userId}`).listen('system_notification.created', () => {
			globalStore.setUnreadNotificationsCount((globalStore.unreadNotificationsCount ?? 0) + 1);
		}).listen('system_notifications.read', () => {
			globalStore.setUnreadNotificationsCount(0);
		});
	}
}

onMounted(() => {
	// Configure global native listeners
	// Callback when the app is resumed from the background while the WebView is loaded
	NativeApp.onResume(() => {
		globalStore.loadUnreadNotificationsCount();
	});

	handleAuthUserChange($auth.id);
});

onBeforeUnmount(() => {
	if ($echo && $auth.id) {
		$echo.unsubscribe(`user.${$auth.id}`);
	}
});

</script>